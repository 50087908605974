import {
	mapGetters
} from "vuex"
import {
	cartList
} from "@/api/goods/cart"
export default {
	data: () => {
		return {
			cartList: [], // รถ เข็น
			checkAll: false,
			totalPrice: "0.00",
			totalCount: 0,
			invalidGoods: [], // คอลเลกชันของสินค้าที่หมดอายุ
			loading: true,
			modifyNum: 1, // ป้องกันไม่ให้ปริมาณหมด
		}
	},
	created() {
		this.getCartList()
	},
	computed: {
		...mapGetters(["defaultGoodsImage"])
	},
	methods: {
		// รับข้อมูลตะกร้าสินค้า
		getCartList() {
			cartList({})
				.then(res => {
					if (res.code >= 0 && res.data.length) {
						this.handleCartList(res.data)
					}
					this.loading = false
				})
				.catch(res => {
					this.loading = false
				})
		},
		// ทํางานกับโครงสร้างข้อมูลตะกร้าสินค้า
		handleCartList(data) {
			this.invalidGoods = []
			this.cartList = []
			var temp = {}
			data.forEach((item, index) => {
				if (item.goods_state == 1) {
					item.checked = true
					if (temp["site_" + item.site_id] != undefined) {
						temp["site_" + item.site_id].cartList.push(item)
					} else {
						temp["site_" + item.site_id] = {
							siteId: item.site_id,
							siteName: item.site_name,
							checked: true,
							cartList: [item]
						}
					}
				} else {
					this.invalidGoods.push(item)
				}
			})

			
			this.invalidGoods.forEach(v => {
				if (v.sku_spec_format) {
					v.sku_spec_format = JSON.parse(v.sku_spec_format)
					console.log(v.sku_spec_format)
				} else {
					v.sku_spec_format = []
				}
			})
			Object.keys(temp).forEach(key => {
				this.cartList.push(temp[key])
			})
			this.calculationTotalPrice()
			this.cartList.forEach(v => {
				v.cartList.forEach(k => {
					if (k.sku_spec_format) {
						k.sku_spec_format = JSON.parse(k.sku_spec_format)
					} else {
						k.sku_spec_format = []
					}
				})
			})
		},
		// วิทยุ
		singleElection(siteIndex, index) {
			this.calculationTotalPrice()
		},
		// เลือกร้านค้าทั้งหมด
		siteAllElection(index) {
			this.cartList[index].cartList.forEach(item => {
				item.checked = this.cartList[index].checked
			})
			this.calculationTotalPrice()
		},
		// เลือกทั้งหมด
		allElection() {
			if (this.cartList.length) {
				this.cartList.forEach(siteItem => {
					siteItem.checked = this.checkAll
					siteItem.cartList.forEach(item => {
						item.checked = this.checkAll
					})
				})
			}
			this.calculationTotalPrice()
		},
		// คํานวณราคาตะกร้าสินค้าทั้งหมด
		calculationTotalPrice() {
			if (this.cartList.length) {
				let totalPrice = 0,
					totalCount = 0,
					siteAllElectionCount = 0

				this.cartList.forEach(siteItem => {
					let siteGoodsCount = 0
					siteItem.cartList.forEach(item => {
						if (item.checked) {
							siteGoodsCount += 1
							totalCount += 1
							totalPrice += item.discount_price * item.num
						}
					})
					if (siteItem.cartList.length == siteGoodsCount) {
						siteItem.checked = true
						siteAllElectionCount += 1
					} else {
						siteItem.checked = false
					}
				})
				this.totalPrice = totalPrice.toFixed(2)
				this.totalCount = totalCount
				this.checkAll = this.cartList.length == siteAllElectionCount
			} else {
				this.totalPrice = "0.00"
				this.totalCount = 0
			}
			this.modifyNum = 1;
		},
		// ลบรายการเดียว
		deleteCart(siteIndex, cartIndex) {
			this.$confirm("คุณแน่ใจหรือไม่ว่าต้องการลบรายชื่อ？", "ข้อมูลที่รวดเร็ว", {
				confirmButtonText: "ยืนยัน",
				cancelButtonText: "ยกเลิก",
				title: "คำเตือน",type: "warning"
			}).then(() => {
				this.$store
					.dispatch("cart/delete_cart", {
						cart_id: this.cartList[siteIndex].cartList[cartIndex].cart_id.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							this.cartList[siteIndex].cartList.splice(cartIndex, 1)
							if (this.cartList[siteIndex].cartList.length == 0) this.cartList.splice(siteIndex, 1)
							this.calculationTotalPrice()
							this.$notify({
								title: 'เรียบร้อยแล้ว',type: "success",
								message: "การลบสําเร็จ"
							})
						} else {
							this.$notify({
								message: res.message,
								title: "คำเตือน",type: "warning"
							})
						}
					})
					.catch(err => {
						this.$notify.error({title: 'Error',message: err.message});
					})
			})
		},
		// ลบตะกร้าสินค้าที่เลือก
		deleteCartSelected() {
			var cartIds = []
			var selectedItem = []
			this.cartList.forEach((siteItem, siteIndex) => {
				siteItem.cartList.forEach((item, cartIndex) => {
					if (item.checked) {
						cartIds.push(item.cart_id)
						selectedItem.push({
							siteIndex: siteIndex,
							cartIndex: cartIndex,
							siteId: siteItem.siteId,
							cartId: item.cart_id
						})
					}
				})
			})

			if (cartIds.length == 0) {
				this.$notify({
					message: "โปรดเลือกรายการที่คุณต้องการลบ",
					title: "คำเตือน",type: "warning"
				})
				return
			}

			this.$confirm("คุณแน่ใจหรือไม่ว่าต้องการลบรายชื่อที่เลือก？", "ข้อมูลที่รวดเร็ว", {
				confirmButtonText: "ยืนยัน",
				cancelButtonText: "ยกเลิก",
				title: "คำเตือน",type: "warning"
			}).then(() => {
				this.$store
					.dispatch("cart/delete_cart", {
						cart_id: cartIds.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							selectedItem.forEach(selectedItem => {
								this.cartList.forEach((siteItem, siteIndex) => {
									siteItem.cartList.forEach((item, cartIndex) => {
										if (selectedItem.cartId == item.cart_id) {
											siteItem.cartList.splice(cartIndex, 1)
										}
										if (siteItem.cartList.length == 0) {
											this.cartList.splice(siteIndex, 1)
										}
									})
								})
							})
							this.calculationTotalPrice()
							this.$notify({
								title: 'เรียบร้อยแล้ว',type: "success",
								message: "การลบสําเร็จ"
							})
						} else {
							this.$notify({
								message: res.message,
								title: "คำเตือน",type: "warning"
							})
						}
					})
					.catch(err => {
						this.$notify.error({title: 'Error',message: err.message});
					})
			})
		},
		// รายการที่ว่างเปล่าที่หมดอายุแล้ว
		clearInvalidGoods() {
			this.$confirm("ยืนยันว่าคุณต้องการล้างรายการเหล่านี้？", "ข้อมูลที่รวดเร็ว", {
				confirmButtonText: "ยืนยัน",
				cancelButtonText: "ยกเลิก",
				title: "คำเตือน",type: "warning"
			}).then(() => {
				var cartIds = []
				this.invalidGoods.forEach(goodsItem => {
					cartIds.push(goodsItem.cart_id)
				})
				if (cartIds.length) {
					this.$store
						.dispatch("cart/delete_cart", {
							cart_id: cartIds.toString()
						})
						.then(res => {
							if (res.code >= 0) {
								this.invalidGoods = []
								this.$notify({
									title: 'เรียบร้อยแล้ว',type: "success",
									message: "การลบสําเร็จ"
								})
							} else {
								this.$notify({
									message: res.message,
									title: "คำเตือน",type: "warning"
								})
							}
						})
						.catch(err => {
							this.$notify.error({title: 'Error',message: err.message});
						})
				}
			})
		},
		// เปลี่ยนจํานวนตะกร้าสินค้า
		cartNumChange(num, params) {
			console.log(num)
			console.log(params)
			if (num < 1 || !num) num = 1;
			// ป้องกันไม่ให้ปริมาณหมด
			this.modifyNum = 0;
			this.$store
				.dispatch("cart/edit_cart_num", {
					num,
					cart_id: this.cartList[params.siteIndex].cartList[params.cartIndex].cart_id
				})
				.then(res => {
					if (res.code >= 0) {
						this.cartList[params.siteIndex].cartList[params.cartIndex].num = num;
						this.calculationTotalPrice();
					} else {
						this.$notify({
							message: res.message,
							title: "คำเตือน",type: "warning"
						});
						this.modifyNum = 1;
					}
				})
				.catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
					this.modifyNum = 1;
				})
		},
		// นิคม
		settlement() {
			if (this.totalCount > 0) {
				let cart_ids = []
				this.cartList.forEach(siteItem => {
					siteItem.cartList.forEach(item => {
						if (item.checked) {
							cart_ids.push(item.cart_id)
						}
					})
				})

				var data = {
					cart_ids: cart_ids.toString()
				}
				this.$store.dispatch("order/setOrderCreateData", data)
				this.$router.push({
					path: "/payment"
				})
			}
		},
		imageError(siteIndex, cartIndex) {
			this.cartList[siteIndex].cartList[cartIndex].sku_image = this.defaultGoodsImage
		},
		imageErrorInvalid(index) {
			this.invalidGoods[index].sku_image = this.defaultGoodsImage
		}
	}
}
